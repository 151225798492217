import React,{useState} from 'react';
import {Link} from "react-router-dom";
import { useHistory } from "react-router-dom";
import { useToasts } from 'react-toast-notifications';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import {cashierService} from '../../../services/cashier';
import Layout from '../../Layout';
import './addCashier.scss';

const SignupSchema = Yup.object().shape({
  login: Yup.string().required('This field is required'),
  password: Yup.string().required('This field is required'),
  first_name: Yup.string().required('This field is required'),
  last_name: Yup.string().required('This field is required'),
  email: Yup.string().required('This field is required'),
  password_confirmation: Yup.string().when("password", {
    is: val => (val && val.length > 0 ? true : false),
    then: Yup.string().required('This field is required').oneOf(
      [Yup.ref("password")],
      "Both password need to be the same"
    )
  })
});

const AddCashier = (props) => {

    const [loading,setLoading] = useState(false);
    const { addToast } = useToasts();
    const history = useHistory();

    //Callback Add Cashier
    const addCashier = (values) => {
      setLoading(true);
      cashierService.addCashier(values).then(
          data => {
              setLoading(false);
              if(data.meta.errors == "user_existe"){
                addToast('The email or username has already been taken.', { appearance: 'error',autoDismiss: true});
              }else if (data.meta.errors == "vender_not_found"){
                addToast('The vender not found.', { appearance: 'error',autoDismiss: true});
              }else if ( data.data.vender == "cashier_added"){
                history.push('/cashiers');
              }
          }
      )}

    return(
        <Layout>
          <div className='control-toolbar'>
              <Link to="/cashiers" className="btn btn-primary login-button">
                  Cancel           
              </Link>
          </div>
          <div className='form-content-layout'>
            <h1 className='center'>Add new cashier</h1>
            <Formik
              initialValues={{
                  email: '',
                  login: '',
                  first_name: '',
                  last_name: '',
                  password: '',
                  password_confirmation: '',
              }}
              validationSchema={SignupSchema}
              onSubmit={addCashier}
              >
              {({ errors, touched }) => (
                  <Form>
                  <div className="form-order">
                    <label htmlFor="email">Email:</label>
                    <Field name="email" className="form-control" placeholder="Email" />
                      {errors.email && touched.email ? (
                          <div className='error_form'>{errors.email}</div>
                      ) : null}
                    <label htmlFor="login">Username:</label>
                    <Field name="login" className="form-control" placeholder="Username" />
                      {errors.login && touched.login ? (
                          <div className='error_form'>{errors.login}</div>
                      ) : null}
                    <div className="flex-2-row">
                      <div>
                        <label htmlFor="login">First name:</label>
                        <Field name="first_name" className="form-control" placeholder="First name" />
                        {errors.first_name && touched.first_name ? (
                            <div className='error_form'>{errors.first_name}</div>
                        ) : null}
                      </div>
                      <div>
                        <label htmlFor="login">Last name:</label>
                        <Field name="last_name" className="form-control" placeholder="Last name" />
                        {errors.last_name && touched.last_name ? (
                            <div className='error_form'>{errors.last_name}</div>
                        ) : null}
                      </div>
                    </div> 
                    <div className="flex-2-row">
                      <div>
                        <label htmlFor="password">Password:</label>
                        <Field type='password' name="password" className="form-control" placeholder="Password" />
                        {errors.password && touched.password ? (
                            <div className='error_form'>{errors.password}</div>
                        ) : null}
                      </div>
                      <div>
                        <label htmlFor="password_confirmation">Confirm password :</label>
                        <Field type='password' name="password_confirmation" className="form-control" placeholder="Confirm password" />
                        {errors.password_confirmation && touched.password_confirmation ? (
                            <div className='error_form'>{errors.password_confirmation}</div>
                        ) : null}
                      </div>
                    </div> 
                    <button type="submit" className="btn btn-primary login-button">
                      {loading ? 'Create...' : 'Create'}            
                    </button>
                  </div>
                  </Form>
              )}
            </Formik>
          </div>
        </Layout>  
    )

} 

export default AddCashier
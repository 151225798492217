import React,{useState} from 'react';
import {Link,useHistory} from "react-router-dom";
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import {orderService} from '../../services/order';
import NumberFormat from 'react-number-format';
import Layout from '../Layout';
import './addOrder.scss';


const OrderSchema = Yup.object().shape({
  orderType: Yup.string().required('You must choose an option'),
  amount_collection: Yup.string().when("orderType", {
    is: val => (val && val == '1' ? true : false),
    then: Yup.string().required('This field is required')
  }),
  points_consumption: Yup.string().when("orderType", {
    is: val => (val && val == '2' ? true : false),
    then: Yup.string().required('This field is required')
  })
});


const AddOrder = (props) => {

  const history = useHistory(); 
  const [loading,setLoading] = useState(false);
  const [messageError,setMessageError] = useState('');
  const [disabledBtn,setDisabledBtn] = useState(true);
  const [firstName,setFirstName] = useState('');
  const [cardNumber,setCardNumber] = useState('');
  const [lastName,setLastName] = useState('');
  const [idCustomer,setIdCustomer] = useState('');
  const [disableStep1,setDisableStep1] = useState(false);
  const [orderType,setOrderType] = useState('');


    //Callback Add Order
    const addOrder = async (values) => {
      setLoading(true);
      if(values.card_number && values.first_name && values.last_name && values.orderType){
        await orderService.addOrder(values).then(data => {
          if(data && data.data && data.data.code == 200 && data.data.order == "order_added"){
            setLoading(false)
            history.push('/orders'); 
          }
        })
      }else{
        setLoading(false)
      }
    }


    //Callback Check Card Number
    const checkCard = async (value) => {
      if(value.length == 17){
        await orderService.checkCardCustomer({card_number: value}).then(data => {
          if(data && data.first_name && data.last_name && data.id){
            setMessageError('');
            setFirstName(data.first_name);
            setLastName(data.last_name);
            setIdCustomer(data.id);
            setDisabledBtn(false);
            setCardNumber(value)
          }else if (data && data.error && data.code == 400){
            setMessageError('Invalid Card Number');
            setFirstName('');
            setLastName('');
            setIdCustomer('');
            setDisabledBtn(true);
          }
        });
      }
    }

    //Pass Step 2
    const nextStep = () => {
      setDisableStep1(true);
    }
    
    // On change Input Radio
    const onChangeRadios = (value) => {
      setOrderType(value);
    }


    return(
            <Layout>
                <div className='control-toolbar'>
                    <Link to="/orders" className="btn btn-primary login-button">
                        Cancel           
                    </Link>
                </div>
                <div className='form-content-layout'>
                  <h1 className='center'>Add new order</h1>
                        <div className='step_wizard'>
                        </div>

                <Formik
                    initialValues={{
                      card_number: cardNumber,
                      first_name:firstName,
                      last_name:lastName,
                      orderType: orderType,
                      idCustomer:idCustomer,
                    }}
                    validationSchema={OrderSchema}
                    enableReinitialize={true}
                    onSubmit={addOrder}
                    >
                    {({ errors, touched }) => (
                  <Form>
                        <div className="form-order">
                          <label htmlFor="ccn">Card Mozoon Number:</label>
                          <NumberFormat name="card_number" format="# #### #### #### ####" mask="_" className="form-control" placeholder="x xxxx xxxx xxxx xxxx" 
                          onValueChange={(values) => checkCard(values.value)}  disabled={disableStep1}/>
                          {messageError && messageError != '' ? (
                            <div className='error_form'>{messageError}</div>
                          ) : null}
                          <div className="flex-2-row">
                            <div>
                              <label >First name:</label>
                              <Field name="first_name" className="form-control" placeholder="First Name" disabled />
                            </div>
                            <div>
                              <label >Last name:</label>
                              <Field name="last_name" className="form-control" placeholder="Last Name" disabled />
                              <Field type='hidden' name="idCustomer" className="form-control" placeholder="Last Name" />
                            </div>
                          </div> 

                          {!disableStep1 &&<button type="button" onClick={nextStep} className="btn btn-primary order-btn" disabled={disabledBtn}>
                            Next          
                          </button>}

                          <div className={disableStep1 ? 'displayStep2' : 'hiddenStep2'}>
                            <div className="inputGroup">
                              <input id="radio1" type="radio" name="orderType" value="1" onChange={() => onChangeRadios("1")}/>
                              <label for="radio1">Points collection</label>
                            </div>
                            <div className="inputGroup">
                              <input id="radio2" type="radio" name="orderType" value="2" onChange={() => onChangeRadios("2")}/>
                              <label for="radio2">Points consumption</label>
                            </div>
                          </div>
                          {errors.orderType && touched.orderType ? (
                              <div className='error_form'>{errors.orderType}</div>
                          ) : null}

                          {orderType && orderType == '1' &&
                          <div>
                            <label >Amount :</label>
                            <Field type='number' name="amount_collection" className="form-control" placeholder="Amount" />
                            {errors.amount_collection && touched.amount_collection ? (
                              <div className='error_form'>{errors.amount_collection}</div>
                            ) : null}
                          </div> }

                          {orderType && orderType == '2' && 
                          <div>
                            <label >Points :</label>
                            <Field type='number' name="points_consumption" className="form-control" placeholder="Points consumption" />
                            {errors.points_consumption && touched.points_consumption ? (
                              <div className='error_form'>{errors.points_consumption}</div>
                            ) : null}
                          </div>}

                          {disableStep1 && <button type="submit" onClick={nextStep}  className="btn btn-primary order-btn" >
                            {loading ? 'Send...' : 'Send'}         
                          </button>}
                          
                        </div>
                  </Form>
                )}
              </Formik>
            </div>
          </Layout>
    )

} 

export default AddOrder
import React,{useState,useEffect,useRef} from 'react';
import {Link} from "react-router-dom";
import { useHistory,useParams } from "react-router-dom";
import { useToasts } from 'react-toast-notifications';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import {cashierService} from '../../../services/cashier';
import Layout from '../../Layout';
import './updateCashier.scss';


const SignupSchema = Yup.object().shape({
  login: Yup.string().required('This field is required'),
  first_name: Yup.string().required('This field is required'),
  last_name: Yup.string().required('This field is required'),
  email: Yup.string().required('This field is required'),
  password: Yup.string().when('type', {
    is: type => type === true,
    then: Yup
      .string()
      .required('This field is required'),
    else: Yup.string().nullable(true),
  }),
  password_confirmation: Yup.string().when("password", {
    is: val => (val && val.length > 0 ? true : false),
    then: Yup.string().required('This field is required').oneOf(
      [Yup.ref("password")],
      "Both password need to be the same"
    )
  })
});

const UpdateCashier = (props) => {
    
    const  { id } = useParams();
    const [loading,setLoading] = useState(false);
    const [userLogin,setUserLogin] = useState('');
    const [userEmail,setUserEmail] = useState('');
    const [userFN,setUserFN] = useState('');
    const [userLN,setUserLN] = useState('');
    const { addToast } = useToasts();
    const history = useHistory();

    //Load Cashier
    useEffect(() => {
      //const user = JSON.parse(localStorage.getItem('user-external'));
      cashierService.getCashier(id).then(
        data => {
          if(data.data.user == "user_not_found"){
            history.push('/404');
          }else if(data.data[0].login){
            setUserLogin(data.data[0].login);
            setUserEmail(data.data[0].email);
            setUserFN(data.data[0].first_name);
            setUserLN(data.data[0].last_name);
          }
        }
      )
    },[])

    //Callback Update Cashier
    const updateCashier = (values) => {
      setLoading(true);
      const user = JSON.parse(localStorage.getItem('user-external'));
      Object.assign(values, {"id_user" : id});
      cashierService.updateCashier(values).then(
          data => {
              setLoading(false);
              if(data.meta.errors == "user_not_found"){
                addToast('User not found.', { appearance: 'error',autoDismiss: true});
              }else if (data.meta.errors == "vender_not_found"){
                addToast('The vender not found.', { appearance: 'error',autoDismiss: true});
              }else if ( data.data.vender == "cashier_updated"){
                addToast('Cashier updated', { appearance: 'succes',autoDismiss: true});
              }
          }
      )}

    return(
      <Layout>  
          <div className='control-toolbar'>
              <Link to="/cashiers" className="btn btn-primary login-button">
                  Cancel           
              </Link>
          </div>
          <div className='form-content-layout'>
            <h1 className='center'>Update cashier</h1>
            <Formik
              initialValues={{
                  email: userEmail,
                  login: userLogin,
                  first_name: userFN,
                  last_name: userLN,
              }}
              enableReinitialize={true}
              validationSchema={SignupSchema}
              onSubmit={updateCashier}
              >
              {({ errors, touched }) => (
                  <Form>
                  <div className="form-order">
                    <label htmlFor="email">Email:</label>
                    <Field name="email"  disabled={true}  className="form-control" placeholder="Email" />
                      {errors.email && touched.email ? (
                          <div className='error_form'>{errors.email}</div>
                      ) : null}
                    <label htmlFor="login">Username:</label>
                    <Field name="login" disabled={true}  className="form-control" placeholder="Username" />
                      {errors.login && touched.login ? (
                          <div className='error_form'>{errors.login}</div>
                      ) : null}
                    <div className="flex-2-row">
                      <div>
                        <label htmlFor="login">First name:</label>
                        <Field name="first_name" className="form-control"   placeholder="First name" />
                        {errors.first_name && touched.first_name ? (
                            <div className='error_form'>{errors.first_name}</div>
                        ) : null}
                      </div>
                      <div>
                        <label htmlFor="login">Last name:</label>
                        <Field name="last_name" className="form-control"  placeholder="Last name" />
                        {errors.last_name && touched.last_name ? (
                            <div className='error_form'>{errors.last_name}</div>
                        ) : null}
                      </div>
                    </div> 
                    <div className="flex-2-row">
                      <div>
                        <label htmlFor="password">Password:</label>
                        <Field type='password' name="password" className="form-control" placeholder="Password" />
                        {errors.password && touched.password ? (
                            <div className='error_form'>{errors.password}</div>
                        ) : null}
                      </div>
                      <div>
                        <label htmlFor="password_confirmation">Confirm password :</label>
                        <Field type='password' name="password_confirmation" className="form-control" placeholder="Confirm password" />
                        {errors.password_confirmation && touched.password_confirmation ? (
                            <div className='error_form'>{errors.password_confirmation}</div>
                        ) : null}
                      </div>
                    </div> 
                    <button type="submit" className="btn btn-primary login-button">
                      {loading ? 'Update...' : 'Update'}            
                    </button>
                  </div>
                  </Form>
              )}
            </Formik>
          </div>
      </Layout>  
    )

} 

export default UpdateCashier
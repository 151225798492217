import React,{useEffect,useState} from 'react';
import {Link,useHistory,useParams} from "react-router-dom";
import Layout from '../../Layout';
import {logService} from '../../../services/log';
import './details.scss'

const DetailsLog = (props) => {
    
  const  { id } = useParams();
  const [loading,setLoading] = useState(true);
  const [data,setData] = useState([]);
  const history = useHistory();

  //get Data
  useEffect(() => {
    logService.getLogById(id).then(
      data => {
          if(data && data.meta && data.meta.errors && data.meta.errors == "log_not_found"){
            history.push('/404');
          }else if(data){
            setLoading(false);
            setData(data.data);
          }
      }
    )
  },[])

  return(
        <Layout>
            {!loading && data  ? <div className='control-toolbar'>
                <div className='height50'>
                  <Link to="/history" className="btn btn-primary login-button">
                    Back to list           
                  </Link>
                </div>

                <div>
                  <h1 className='heading_title'>Log #{data.id}</h1>
                  <div className='list-item'>
                    {data && data.title && <div className='detail-item'>
                      <div className='detail-title'>Action : </div>
                      <div className='detail-value'>{data.title}</div>
                    </div>}
                    {data && data.vender && data.vender.user && data.vender.user.login &&  <div className='detail-item'>
                      <div className='detail-title'>Login : </div>
                      <div className='detail-value'>{data.vender.user.login} </div>
                    </div>}
                    {data && data.vender && data.vender.user && data.vender.user.email &&  <div className='detail-item'>
                      <div className='detail-title'>Email : </div>
                      <div className='detail-value'>{data.vender.user.email} </div>
                    </div>}
                    {data && data.vender && data.vender.user && data.vender.user.first_name &&  <div className='detail-item'>
                      <div className='detail-title'>First name : </div>
                      <div className='detail-value'>{data.vender.user.first_name} </div>
                    </div>}
                    {data && data.vender && data.vender.user && data.vender.user.last_name &&  <div className='detail-item'>
                      <div className='detail-title'>Last name : </div>
                      <div className='detail-value'>{data.vender.user.last_name} </div>
                    </div>}
                    {data  && data.created_at && <div className='detail-item'>
                      <div className='detail-title'> Date : </div>
                      <div className='detail-value'>{data.created_at}</div>
                    </div>}
                  </div>
                </div>

                
            </div> 
            : <h1 className='heading_title_loading'>Loading... </h1> }
        </Layout>   
  )

} 

export default DetailsLog
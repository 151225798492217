import React,{useState,useEffect} from 'react';
import Layout from '../Layout';
import {dashboardService} from '../../services/dashboard';
import { Pie } from 'react-chartjs-2';
import { DateRangePicker } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { addMonths,format,startOfMonth,endOfMonth } from 'date-fns';
import {defaultStaticRanges} from './range'

import './dashboard.scss'


const Dashboard = (props) => {

    const [loading,setLoading] = useState(true);
    const [show,setShow] = useState(false);
    const [data,setData] = useState([]);
    const [dateFrom,setDateFrom] = useState(null);
    const [dateTo,setDateTo] = useState(null);

    const [state, setState] = useState([
      {
        startDate: startOfMonth(addMonths(new Date(), -1)),
        endDate: endOfMonth(addMonths(new Date(), -1)),
        key: 'selection'
      }
    ]);

    const  pad = (number) => {
      if (number < 10) {
        return '0' + number;
      }
      return number;
    }

    const toISOString = (date) => {
      var mS = ['','Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
      const str_date = ""+date+"";
      const year = str_date.substring(11, 15);
      const month = str_date.substring(4, 7);
      const day = str_date.substring(8, 10);
      const mm = mS.indexOf(month);
      return year +
        '-' + pad(mm) +
        '-' + day
    };


    //Get Data First Time
    useEffect( async () => {

      const date_start = state[0].startDate;
      const date_End = state[0].endDate
      // Date to String
      const str_start = ""+date_start+"";
      const str_End = ""+date_End+"";
      setDateFrom(str_start.substring(0, 15));
      setDateTo(str_End.substring(0, 15));
      setLoading(true);
      //      
      const from = toISOString(date_start);
      const to = toISOString(date_End);
      await dashboardService.getStatistic(from,to).then(
        data => {
            setLoading(false);
            if(data && data.data && data.data.code == 200){
              setData(data.data);
            }
        }
      )
    },[state])
    

    const dataPie = {
      labels: ['Collection (Pts)', 'Compsuption (Pts)'],
      datasets: [
        {
          label: '# of Votes',
          data: data && data.data_collection && data.data_collection[0] ? [data.data_collection[0].sums,data.data_compsuption[0].sums] : [0,0],
          backgroundColor: [
            'rgba(255, 99, 132, 0.2)',
            'rgba(54, 162, 235, 0.2)',
          ],
          borderColor: [
            'rgba(255, 99, 132, 1)',
            'rgba(54, 162, 235, 1)',
          ],
          borderWidth: 1,
        },
      ],
    };


    return(
          <Layout>
              <div className='control-toolbar'>
                <h1 className='heading_title'>Dashboard</h1>
              </div>
              <div className='date_ranger_blocm'>
                <button onClick={() => setShow(!show)} className='button_ranger'>{dateFrom} - {dateTo} 
                <span className='icon_ranger' ><svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 24 24" fill="none" stroke="#000" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                    <rect x="3" y="4" width="18" height="18" rx="2" ry="2"/>
                    <line x1="16" y1="2" x2="16" y2="6"/>
                    <line x1="8" y1="2" x2="8" y2="6"/>
                    <line x1="3" y1="10" x2="21" y2="10"/>
                </svg></span>
                </button>
                {show && <div><DateRangePicker
                  onChange={item => {
                    setState([item.selection])
                    console.log("item.selection",item.selection)
                  }
                  }
                  showSelectionPreview={true}
                  moveRangeOnFirstSelection={false}
                  months={2}
                  ranges={state}
                  direction="horizontal"
                  className='date-ranger'
                  showSelectionPreview={false}
                  showDateDisplay={false}
                  showPreview={false}
                  staticRanges={defaultStaticRanges}
                /><div onClick={() => setShow(!show)} className='mask_ranger'></div></div>}
              </div>
              {!loading ? <div><div className='box_content'>
                {data && data.code &&
                <div className="box_count">
                  <div className="box_titre"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-layers"><polygon points="12 2 2 7 12 12 22 7 12 2"></polygon><polyline points="2 17 12 22 22 17"></polyline><polyline points="2 12 12 17 22 12"></polyline></svg> Orders</div>
                  <div className="box_value">{data.nb_order}</div>
                </div>}
                {data && data.code &&
                <div className="box_count">
                  <div className="box_titre"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-layers"><polygon points="12 2 2 7 12 12 22 7 12 2"></polygon><polyline points="2 17 12 22 22 17"></polyline><polyline points="2 12 12 17 22 12"></polyline></svg> Collection</div>
                  <div className="box_value">{data.nb_order_collection}</div>
                </div>}
                {data && data.code &&
                <div className="box_count">
                  <div className="box_titre"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-layers"><polygon points="12 2 2 7 12 12 22 7 12 2"></polygon><polyline points="2 17 12 22 22 17"></polyline><polyline points="2 12 12 17 22 12"></polyline></svg> Compsuption</div>
                  <div className="box_value">{data.nb_order_compsuption}</div>
                </div>}
                {data && data.code &&
                <div className="box_count">
                  <div className="box_titre"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-users"><path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path><circle cx="9" cy="7" r="4"></circle><path d="M23 21v-2a4 4 0 0 0-3-3.87"></path><path d="M16 3.13a4 4 0 0 1 0 7.75"></path></svg> Cashiers</div>
                  <div className="box_value">{data.nb_cashier}</div>
                </div>}
              </div>
              <div className='pie'>
                { data && data.data_collection && data.data_collection[0] && data.data_collection[0].sums ? <Pie  data={dataPie} /> : <span className='noDataAvaibled'>No data available</span> }
              </div>
              </div>
              : <h1 className='heading_title_loading'>Loading... </h1> }
          </Layout>   
    )

} 




export default Dashboard
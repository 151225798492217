import React,{useContext} from 'react';
import { ThemeContext } from '../../utils/context/ThemeContext';
import Navbar from '../Navbar';
import Topbar from '../Topbar';

const Layout = ({children}) => {

    const user = JSON.parse(localStorage.getItem('user-external'));
    const { menuOpen } = useContext(ThemeContext);
    const classMenu = !menuOpen ? 'layout_right opned' : 'layout_right clossed';

    return(
        <div className="layout">
            <Navbar />
            <div className={classMenu}>
                <Topbar />
                <div className='layout-row'>
                    {children}
                </div>
            </div>
        </div>
    )

} 

export default Layout
import React,{useEffect,useState} from 'react';
import DataTable, { createTheme } from 'react-data-table-component';
import {Link} from "react-router-dom";
import { useHistory } from "react-router-dom";
import FilterComponent from './Filter';
import swal from 'sweetalert';
import Layout from '../Layout';
import {cashierService} from '../../services/cashier';
import './cashier.scss'

const Cashier = (props) => {

  const [loading,setLoading] = useState(true);
  const [deleting,setDeleting] = useState(false);
  const [data,setData] = useState([]);
  const history = useHistory();

  //get Data
  useEffect(() => {
    const user = JSON.parse(localStorage.getItem('user-external'));
    cashierService.getCashiers(user.vender_id).then(
      data => {
          setLoading(false);
          setData(data.data);
      }
    )
  },[deleting])

  //Delete Item
  const deleteItem = (id) => {
    setDeleting(false);
    swal({
      title: "Are you sure?",
      text: "Are you sure you want to delete the item? ",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
    .then((willDelete) => {
      if (willDelete) {
        const user = JSON.parse(localStorage.getItem('user-external'));
        cashierService.deletetCashier(id).then(
          data => {
            setDeleting(true);
            if(data.data.vender == "cashier_deleted"){
              swal(`Your item  has been deleted!`, {
                icon: "success",
              });
            }
          }
        )
      } else {
       // swal("Your imaginary file is safe!");
      }
    });
  }

  const columns = [
      {
        name: 'ID',
        selector: 'id',
        sortable: true,
      },
      {
        name: 'First name',
        selector: 'first_name',
        sortable: true,
      },
      {
        name: 'Last name',
        selector: 'last_name',
        sortable: true,
      },
      {
        name: 'Email',
        selector: 'email',
        sortable: true,
      },
      {
        name: 'Username',
        selector: 'login',
        sortable: true,
      },
      {
        cell:(row) => <div className='table-btn'><button className="btn btn-default" onClick={() => history.push(`/update-cashier/${row.id}`)} id={row.id}>Update</button>
        <button className="btn btn-default" onClick={() => deleteItem(row.id)} id={row.id}>Delete</button>
        <button className="btn btn-default" onClick={() => history.push(`/cashier/${row.id}`)} id={row.id}>View</button></div>,
        ignoreRowClick: true,
        allowOverflow: true,
        button: true,
        width: '250px',
      },
  ];

  createTheme('solarized', {
      text: {
        primary: '#000',
        secondary: '#2aa198',
      },
      background: {
        default: '#fff',
      },
      context: {
        background: '#cb4b16',
        text: '#FFFFFF',
      },
      divider: {
        default: '#ccc',
      },
      action: {
        button: 'rgba(0,0,0,.54)',
        hover: 'rgba(0,0,0,.08)',
        disabled: 'rgba(0,0,0,.12)',
      },
  });


    return(
      <Layout>
            <div className='control-toolbar'>
                <h1 className='heading_title'>Cashiers</h1>
                <Link to="/add-cashier" className="btn btn-primary login-button">
                    + Add new cashier            
                </Link>
                <div className='filter'>
                  <FilterComponent setData={setData} setLoading={setLoading} />
                </div>
            </div>
            <DataTable
                title="Cashiers"
                columns={columns}
                pagination
                theme="solarized"
                selectableRows 
                data={data}
                progressPending={loading}
                striped
                persistTableHead
                responsive
            />
    </Layout>  
  )
}


export default Cashier
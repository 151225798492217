import React,{useState} from 'react';
import { Formik, Form, Field } from 'formik';
import {orderService} from '../../services/order';

const FilterComponent = ({setLoading , setData}) => {
      
    const [filterCardNumber, setFilterCardNumber] = useState('');
    //const [filterLastName, setFilterLastName] = useState('');
    //const [filterLogin, setFilterLogin] = useState('');
    const [orderType, setOrderType] = useState('');

    const [loadingField1, setLoadingField1] = useState(false);
    const [loadingField2, setLoadingField2] = useState(false);
    const [loadingField3, setLoadingField3] = useState(false);
    const [loadingField4, setLoadingField4] = useState(false);
  
    //Update Filter
    const getFilter = async (card_number,type) => {
      await orderService.orderFilter(card_number,type).then(
        data => {
            setLoading(false);
            setData(data.data);
            setLoadingField1(false);
            setLoadingField2(false);
            setLoadingField3(false);
            setLoadingField4(false);
        }
      )
    }

    //Field Card Number
    const setFieldCardNumber = async (value) => {
        setFilterCardNumber(value);
        setLoadingField1(true);
        getFilter(value,orderType);
    }

  
    //Field Order Type
    const setFieldType = async (value) => {
        setOrderType(value);
        setLoadingField4(true);
        getFilter(filterCardNumber,value);
    }

    return (<>
        <Formik
            initialValues={{
                card_number: filterCardNumber,
                //first_name: filterFirstName,
                //last_name: filterLastName,
                order: orderType,
            }}
            //onSubmit={getFilter}
            >
            {({ errors, touched }) => (
                <Form>
                <div className="filterBlock">
                    <div className='filterItem'>
                        <label htmlFor="login">Card Number :</label>
                        <Field name="card_number" className="field-Filter" value={filterCardNumber}  onChange={e => setFieldCardNumber(e.target.value)} />
                        {loadingField1 && <span className='loader'></span>}
                    </div>
                    {/* <div className='filterItem'>
                      <label htmlFor="login">First name:</label>
                      <Field name="first_name" className="field-Filter" value={filterFirstName}  onChange={e => setFieldFN(e.target.value)} />
                      {loadingField2 && <span className='loader'></span>}
                    </div>
                    <div className='filterItem'>
                      <label htmlFor="login">Last name:</label>
                      <Field name="last_name" className="field-Filter" value={filterLastName}  onChange={e => setFieldLN(e.target.value)} />
                      {loadingField3 && <span className='loader'></span>}
                    </div> */}
                    <div className='filterItem'>
                      <label htmlFor="login">Type :</label>
                      <Field as="select" name="order" className='select-Filter' value={orderType} onChange={e => setFieldType(e.target.value)}>
                        <option value="">All</option>
                        <option value="1">Collection</option>
                        <option value="2">Compsuption</option>
                      </Field>
                      {loadingField4 && <span className='loader'></span>}
                    </div>
                </div>
                </Form>
            )}
      </Formik>
    </>
    );
}

export default FilterComponent;
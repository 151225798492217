import React from 'react';
import Layout from '../Layout';
import {Link} from "react-router-dom";
import './pageNotFound.scss'

const PageNotFound = () => {

    return(
        <Layout>
            <div className='block_notFound'>
                <h1 className='title_notFound'>404</h1>
                <p className='description_notFound'>The page you are looking for does not exist. How you got here is a mystery.<br />
                    But you can click the button below to go back to the homepage
                </p>
                <Link to="/" className="btn btn-primary login-button backToHome">
                        Back to home           
                </Link>
            </div>
        </Layout>   
    )

} 

export default PageNotFound
import React,{useContext} from 'react';
import './navbar.scss'
import useCheckMobileScreen from '../../utils/hooks/useCheckMobileScreen';
import { ThemeContext } from '../../utils/context/ThemeContext';
import {useHistory,useLocation} from "react-router-dom";

const Navbar = () => {

    const user = JSON.parse(localStorage.getItem('user-external'));
    const { menuOpen,setMenuOpen } = useContext(ThemeContext);
    const isMobile = useCheckMobileScreen();
    const history = useHistory();
    const pathname = useLocation().pathname;
    

    //Navigate To Path
    const NavigateTo = (url) => {
        if(isMobile){
            setMenuOpen(true);
            history.push(`${url}`);
        }else{
            history.push(`${url}`);
        }
    }

    const classMenu = !menuOpen ? 'opned' : 'clossed';

    return(
    <div>
    {!menuOpen && isMobile && <div className='maskOpenMenu' onClick={() => setMenuOpen(true)}></div>}
    <div id="fixed-menu" className={classMenu}>
    <a onClick={() => NavigateTo('/')} className='logo_navbar' >
        <img src="https://crm.bluesalon.com/backendskins/abuissa/assets/img/blue_salon-white.png" className="logoNavbar" />
    </a>
    <div className="user">
        <img src="//www.gravatar.com/avatar/2d6826023f031865b164c14f78254c0e?s=90&amp;d=mm" />
    <div>
        <b>{user && user.first_name && user.last_name && user.first_name +' '+user.last_name}</b>
        <span className="role">
            {user && user.role && user.role == 7 && 'Vendor Management'}  
            {user && user.role && user.role == 6 && 'Vendor'}       
        </span>
    </div>    
    </div>
    <nav className="navbar control-toolbar navbar-mode-inline" id="layout-mainmenu" role="navigation">
        <ul className="nav mainmenu-nav">
                <li className=" svg-icon-container svg-active-effects">
                    <a onClick={() => NavigateTo('/dashboard')}  className={pathname && pathname.includes("dashboard") ? 'active' : 'no-active'}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-home"><path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path><polyline points="9 22 9 12 15 12 15 22"></polyline></svg>
                        <span className="nav-label">
                            Dashboard                    
                        </span>
                    </a>
                </li>
                <li className=" svg-icon-container svg-active-effects">
                    <a onClick={() => NavigateTo('/orders')} className={pathname && pathname.includes("orders") ? 'active' : 'no-active'}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-layout"><rect x="3" y="3" width="18" height="18" rx="2" ry="2"></rect><line x1="3" y1="9" x2="21" y2="9"></line><line x1="9" y1="21" x2="9" y2="9"></line></svg>
                        <span className="nav-label">
                            Orders                    
                        </span>
                    </a >
                </li>
                {user && user.role == 7 && <li className=" svg-icon-container svg-active-effects">
                    <a  onClick={() => NavigateTo('/cashiers')} className={pathname && pathname.includes("cashiers") ? 'active' : 'no-active'}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-users"><path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path><circle cx="9" cy="7" r="4"></circle><path d="M23 21v-2a4 4 0 0 0-3-3.87"></path><path d="M16 3.13a4 4 0 0 1 0 7.75"></path></svg>
                        <span className="nav-label">
                            Cashiers                    
                        </span>
                    </a>
                </li>
                }
                <li className=" svg-icon-container svg-active-effects">
                    <a onClick={() => NavigateTo('/history')}  className={pathname && pathname.includes("history") ? 'active' : 'no-active'}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-target"><circle cx="12" cy="12" r="10"></circle><circle cx="12" cy="12" r="6"></circle><circle cx="12" cy="12" r="2"></circle></svg>
                        <span className="nav-label">
                            History                    
                        </span>
                    </a>
                </li>
                
                
        </ul>
    </nav>       
    </div> 
    </div> )

} 

export default Navbar
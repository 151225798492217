import React,{useEffect,useState} from 'react';
import {Link,useHistory,useParams} from "react-router-dom";
import Layout from '../../Layout';
import {orderService} from '../../../services/order';
import './details.scss'

const DetailsOrder = (props) => {
    
  const  { id } = useParams();
  const [loading,setLoading] = useState(true);
  const [data,setData] = useState([]);
  const history = useHistory();

  //get Data
  useEffect(() => {
    orderService.getOrderById(id).then(
      data => {
          if(data && data.meta && data.meta.errors && data.meta.errors == "order_not_found"){
            history.push('/404');
          }else if(data){
            setLoading(false);
            setData(data.data);
          }
      }
    )
  },[])

  return(
        <Layout>
            {!loading && data  ? <div className='control-toolbar'>
                <div className='height50'>
                  <Link to="/orders" className="btn btn-primary login-button">
                    Back to list           
                  </Link>
                </div>

                <div>
                  <h1 className='heading_title'>Order #{data.id}</h1>
                  <div className='list-item'>
                    {data && data.card && data.card.card_number && <div className='detail-item'>
                      <div className='detail-title'>Card Number : </div>
                      <div className='detail-value'>{data.card.card_number}</div>
                    </div>}
                    {data && data.card && data.card.first_name &&  <div className='detail-item'>
                      <div className='detail-title'>Customer First name : </div>
                      <div className='detail-value'>{data.card.first_name} </div>
                    </div>}
                    {data && data.card && data.card.last_name && <div className='detail-item'>
                      <div className='detail-title'>Customer Last name : </div>
                      <div className='detail-value'>{data.card.last_name} </div>
                    </div>}
                    {data && data.orderType && <div className='detail-item'>
                      <div className='detail-title'>Type : </div>
                      <div className='detail-value'>{data.orderType == '1' ? 'Collection' : 'Compsuption'} </div>
                    </div>}
                    {data && data.points && data.card && data.card.consumption  && <div className='detail-item'>
                      <div className='detail-title'> Compsuption : </div>
                      <div className='detail-value'>{data.points+' Pts '} = {parseFloat(data.card.consumption).toFixed(2)+' QAR'}</div>
                    </div>}
                    {data  && data.amount && data.card && data.card.collection  && <div className='detail-item'>
                      <div className='detail-title'> Collection : </div>
                      <div className='detail-value'>{data.amount +' QAR '} = {parseFloat(data.card.collection).toFixed(2)+' Pts'}</div>
                    </div>}
                    {data  && data.created_at && <div className='detail-item'>
                      <div className='detail-title'> Date : </div>
                      <div className='detail-value'>{data.created_at}</div>
                    </div>}
                  </div>
                  <h2 className='h2_title'>Vendor</h2>
                  <div className='list-item'>
                    {data && data.vender && data.vender.name_vender && <div className='detail-item'>
                      <div className='detail-title'>Vendor : </div>
                      <div className='detail-value'>{data.vender.name_vender}</div>
                    </div>}
                    {data && data.vender && data.vender.phone && <div className='detail-item'>
                      <div className='detail-title'>Phone : </div>
                      <div className='detail-value'>{data.vender.phone}</div>
                    </div>}
                    {data && data.vender && data.vender.city && <div className='detail-item'>
                      <div className='detail-title'>City : </div>
                      <div className='detail-value'>{data.vender.city}</div>
                    </div>}
                    {data && data.vender && data.vender.adress && <div className='detail-item'>
                      <div className='detail-title'>Adress : </div>
                      <div className='detail-value'>{data.vender.adress}</div>
                    </div>}
                    {data && data.vender && data.vender.description && <div className='detail-item'>
                      <div className='detail-title'>Description : </div>
                      <div className='detail-value'>{data.vender.description}</div>
                    </div>}
                    {data && data.vender && data.vender.activity && <div className='detail-item'>
                      <div className='detail-title'>Activity : </div>
                      <div className='detail-value'>{data.vender.activity}</div>
                    </div>}
                    {data && data.loyalty && data.loyalty.pts_collection && data.loyalty.qar_collection && <div className='detail-item'>
                      <div className='detail-title'>Points collection : </div>
                      <div className='detail-value'>{data.loyalty.pts_collection} Pts = {data.loyalty.qar_collection} QAR</div>
                    </div>}
                    {data && data.loyalty && data.loyalty.qar_compsuption && data.loyalty.pts_compsuption && <div className='detail-item'>
                      <div className='detail-title'>Points compsuption : </div>
                      <div className='detail-value'>{data.loyalty.qar_compsuption} QAR = {data.loyalty.pts_compsuption} Pts</div>
                    </div>}
                  </div>
                </div>

                
            </div> 
            : <h1 className='heading_title_loading'>Loading... </h1> }
        </Layout>   
  )

} 

export default DetailsOrder
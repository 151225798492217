import React,{useEffect,useState} from 'react';
import DataTable, { createTheme } from 'react-data-table-component';
import {Link,useHistory} from "react-router-dom";
import FilterComponent from './Filter';
import Layout from '../Layout';
import {orderService} from '../../services/order';
import './orders.scss'

const Orders = (props) => {

    const [loading,setLoading] = useState(true);
    const [deleting,setDeleting] = useState(false);
    const [data,setData] = useState([]);
    const history = useHistory();

    //get Data
    useEffect(() => {
      orderService.getOrders().then(
        data => {
            setLoading(false);
            setData(data.data);
        }
      )
    },[])

    const columns = [
        {
          name: 'Id',
          selector: 'id',
          sortable: true,
        },
        {
          name: 'Vendor',
          selector: 'vender.name_vender',
          sortable: true,
        },
        {
          name: 'Card Number',
          selector: 'card.card_number',
          sortable: false,
        },
        {
          name: 'Created At',
          selector: 'created_at',
          sortable: false,
        },
        {
          name: 'Type',
          cell:(row) => <span>{row.orderType == '1' ? 'Collection' : 'Compsuption'}</span>,
          ignoreRowClick: true,
        },
        {
          name: 'Pts/QAR',
          cell:(row) => <span>{row.orderType == '1' ? '+ '+parseFloat(row.card.collection).toFixed(2)+' Pts' : '- '+parseFloat(row.card.consumption).toFixed(2)+' QAR'}</span>,
          ignoreRowClick: true,
        },
        {
          name: 'Actions',
          cell:(row) => <div className='table-btn'><button className="btn btn-default" onClick={() => history.push(`/order/${row.id}`)} id={row.id}>View</button></div>,
          ignoreRowClick: true,
          allowOverflow: true,
          button: true,
          width: '150px',
        },
    ];

    createTheme('solarized', {
        text: {
          primary: '#000',
          secondary: '#2aa198',
        },
        background: {
          default: '#fff',
        },
        context: {
          background: '#cb4b16',
          text: '#FFFFFF',
        },
        divider: {
          default: '#ccc',
        },
        action: {
          button: 'rgba(0,0,0,.54)',
          hover: 'rgba(0,0,0,.08)',
          disabled: 'rgba(0,0,0,.12)',
        },
      });

    return(
          <Layout>
              <div className='control-toolbar'>
                  <h1 className='heading_title'>Orders</h1>
                  <Link to="/add-order" className="btn btn-primary login-button">
                      + Add new order            
                  </Link>
                  <div className='filter'>
                    <FilterComponent setData={setData} setLoading={setLoading} />
                  </div>
              </div>
              <DataTable
                  title="Orders"
                  columns={columns}
                  pagination
                  theme="solarized"
                  selectableRows 
                  data={data}
                  progressPending={loading}
                  striped
                  persistTableHead
                  responsive
              />
          </Layout>   
    )

} 

export default Orders
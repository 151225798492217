import axios from "axios";
import { getToken } from "../utils/helper";

//Login
const login = (login, password) => {
    return axios
        .post(`${process.env.REACT_APP_API_URL}/login`, { login, password })
        .then((response) => {
            if (response.data.data.email) {
                localStorage.setItem(
                    "user-external",
                    JSON.stringify(response.data.data)
                );
            }
            return response.data.data;
        });
};

//Logout
const logout = () => {
    const config = getToken();
    return axios
        .post(`${process.env.REACT_APP_API_URL}/logout`, {}, config)
        .then((response) => {
            return response.data;
        });
};

export const authService = {
    login,
    logout,
};

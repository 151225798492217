import axios from "axios";
import { getToken } from "../utils/helper";

//check Card Customer
const checkCardCustomer = (card_number) => {
    const config = getToken();
console.log(`${process.env.REACT_APP_API_URL}/check-card-customer`)
    return axios
        .post(
            `${process.env.REACT_APP_API_URL}/check-card-customer`,
            card_number,
            config
        )
        .then((response) => {
            return response.data;
        });
};

//check Card Customer
const addOrder = (order) => {
    const config = getToken();

    return axios
        .post(`${process.env.REACT_APP_API_URL}/add-order`, order, config)
        .then((response) => {
            return response.data;
        });
};

//get order list
const getOrders = () => {
    const config = getToken();

    return axios
        .get(`${process.env.REACT_APP_API_URL}/orders`, config)
        .then((response) => {
            return response.data;
        });
};

// filter
const orderFilter = (card_number, orderType) => {
    const config = getToken();

    return axios
        .get(
            `${process.env.REACT_APP_API_URL}/orders-filter?card_number=${card_number}&orderType=${orderType}`,
            config
        )
        .then((response) => {
            return response.data;
        });
};

//Get order by Id
const getOrderById = (id) => {
    const config = getToken();

    return axios
        .get(`${process.env.REACT_APP_API_URL}/order/${id}`, config)
        .then((response) => {
            return response.data;
        });
};

export const orderService = {
    checkCardCustomer,
    addOrder,
    getOrders,
    orderFilter,
    getOrderById,
};

import React,{useState} from 'react';
import { Formik, Form, Field } from 'formik';
import {cashierService} from '../../services/cashier';

const FilterComponent = ({setLoading , setData}) => {
      
    const [filterFirstName, setFilterFirstName] = useState('');
    const [filterLastName, setFilterLastName] = useState('');
    const [filterLogin, setFilterLogin] = useState('');
    const [filterEmail, setFilterEmail] = useState('');

    const [loadingField1, setLoadingField1] = useState(false);
    const [loadingField2, setLoadingField2] = useState(false);
    const [loadingField3, setLoadingField3] = useState(false);
    const [loadingField4, setLoadingField4] = useState(false);
  
    //Update Filter
    const getFilter = async (login,fn,ln,email) => {
      await cashierService.cashiersFilter(login,fn,ln,email).then(
        data => {
            setLoading(false);
            setData(data.data);
            setLoadingField1(false);
            setLoadingField2(false);
            setLoadingField3(false);
            setLoadingField4(false);
        }
      )
    }

    //Field Login
    const setFieldLogin = async (value) => {
        setFilterLogin(value);
        setLoadingField1(true);
        getFilter(value,filterFirstName,filterLastName,filterEmail);
    }

    //Field First Name
    const setFieldFN = async (value) => {
        setFilterFirstName(value);
        setLoadingField2(true);
        getFilter(filterLogin,value,filterLastName,filterEmail);
    }

    //Field Last Name
    const setFieldLN = async (value) => {
        setFilterLastName(value);
        setLoadingField3(true);
        getFilter(filterLogin,filterFirstName,value,filterEmail);
    }
  
    //Field Email
    const setFieldEmail = async (value) => {
        setFilterEmail(value);
        setLoadingField4(true);
        getFilter(filterLogin,filterFirstName,filterLastName,value);
    }

    return (<>
        <Formik
            initialValues={{
                login: filterLogin,
                first_name: filterFirstName,
                last_name: filterLastName,
                email: filterEmail,
            }}
            >
            {({ errors, touched }) => (
                <Form>
                <div className="filterBlock">
                    <div className='filterItem'>
                      <label htmlFor="login">First name :</label>
                      <Field name="first_name" className="field-Filter" value={filterFirstName}  onChange={e => setFieldFN(e.target.value)} />
                      {loadingField2 && <span className='loader'></span>}
                    </div>
                    <div className='filterItem'>
                      <label htmlFor="login">Last name :</label>
                      <Field name="last_name" className="field-Filter" value={filterLastName}  onChange={e => setFieldLN(e.target.value)} />
                      {loadingField3 && <span className='loader'></span>}
                    </div>
                    <div className='filterItem'>
                        <label htmlFor="login">Username :</label>
                        <Field name="login" className="field-Filter" value={filterLogin}  onChange={e => setFieldLogin(e.target.value)} />
                        {loadingField1 && <span className='loader'></span>}
                    </div>
                    <div className='filterItem'>
                      <label htmlFor="login">Email :</label>
                      <Field name="email" className="field-Filter" value={filterEmail}  onChange={e => setFieldEmail(e.target.value)} />
                      {loadingField4 && <span className='loader'></span>}
                    </div>
                </div>
                </Form>
            )}
      </Formik>
    </>
    );
}

export default FilterComponent;
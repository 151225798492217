import axios from "axios";
import { getToken } from "../utils/helper";

//add new cashier
const addCashier = (cashier) => {
    const config = getToken();

    return axios
        .post(`${process.env.REACT_APP_API_URL}/add-cashier`, cashier, config)
        .then((response) => {
            return response.data;
        });
};

//Get all Cashier
const getCashiers = () => {
    const config = getToken();

    return axios
        .get(`${process.env.REACT_APP_API_URL}/cashiers`, config)
        .then((response) => {
            return response.data;
        });
};

//Update cashier
const updateCashier = (cashier) => {
    const config = getToken();

    return axios
        .post(
            `${process.env.REACT_APP_API_URL}/cashier/update`,
            cashier,
            config
        )
        .then((response) => {
            return response.data;
        });
};

//get cashier by id
const getCashier = (id) => {
    const config = getToken();

    return axios
        .get(`${process.env.REACT_APP_API_URL}/cashier/${id}`, config)
        .then((response) => {
            return response.data;
        });
};

//delete cashier
const deletetCashier = (id_user) => {
    const config = getToken();

    return axios
        .post(
            `${process.env.REACT_APP_API_URL}/cashier/delete`,
            { id_user },
            config
        )
        .then((response) => {
            return response.data;
        });
};

// filter
const cashiersFilter = (login, first_name, last_name, email) => {
    const config = getToken();

    return axios
        .get(
            `${process.env.REACT_APP_API_URL}/cashiers-filter?login=${login}&first_name=${first_name}&last_name=${last_name}&email=${email}`,
            config
        )
        .then((response) => {
            return response.data;
        });
};

//Get Cashier by Id
const getCashierById = (id) => {
    const config = getToken();

    return axios
        .get(`${process.env.REACT_APP_API_URL}/view/cashier/${id}`, config)
        .then((response) => {
            return response.data;
        });
};

export const cashierService = {
    addCashier,
    getCashiers,
    updateCashier,
    getCashier,
    deletetCashier,
    cashiersFilter,
    getCashierById,
};

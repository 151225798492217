import React,{useState} from 'react';
import { useHistory } from "react-router-dom";
import logo from '../../assets/logo.png';
import { Formik, Form, Field } from 'formik';
import { useToasts } from 'react-toast-notifications';
import * as Yup from 'yup';
import {authService} from '../../services/auth';
import './login.scss'

const SignupSchema = Yup.object().shape({
    login: Yup.string().required('This field is required'),
    password: Yup.string().required('This field is required'),
});

const Login = (props) => {

    const history = useHistory();
    const { addToast } = useToasts();
    const [loading,setLoading] = useState(false);

    const submitLogin = (values) => {
        setLoading(true);
        authService.login(values.login,values.password).then(
            user => {
                setLoading(false);
                if(user && user.email){
                    history.push('/dashboard');
                }
            }
        ).catch(
            error => {
                setLoading(false);
                addToast('A user was not found with the given credentials.', { appearance: 'error',autoDismiss: true});
            }
        );
    }

    return(
        <div className="pageLogin">
            <div className='headerLogin'>
                <div className='logo-header'> 
                    <img src={logo} className='imageLeft'/>
                </div>    
            </div>
            <div className='formContent'>
                <div className="outer-form-container">
                    <h1>Welcome back</h1>
                    <Formik
                        initialValues={{
                            login: '',
                            password: '',
                        }}
                        validationSchema={SignupSchema}
                        onSubmit={submitLogin}
                        >
                        {({ errors, touched }) => (
                            <Form>
                                <div className="form-elements" role="form">
                                    <div className="form-login-bottom">
                                        <div className='flex-column'>
                                        <Field name="login" className="form-control" placeholder="Username" />
                                            {errors.login && touched.login ? (
                                                <div className='error_form_login'>{errors.login}</div>
                                            ) : null}
                                        </div>
                                        <div className='flex-column'>
                                            <Field type='password' name="password" className="form-control" placeholder="Password" />
                                            {errors.password && touched.password ? (
                                                <div className='error_form_login'>{errors.password}</div>
                                            ) : null}
                                        </div>
                                        <button type="submit" className="btn btn-primary login-button">
                                            {loading ? 'Login...' : 'Login'}            
                                        </button>
                                    </div>

                                    <p className="pull-right forgot-password">
                                        <a href="https://crm.bluesalon.com/crm/backend/auth/restore" className="text-muted">
                                            Forgot your password?            
                                        </a>
                                    </p>

                                </div>
                            </Form>  
                        )}
                    </Formik>                      
                </div>
            </div>
        </div>    
    )

} 

export default Login
import axios from "axios";
import { getToken } from "../utils/helper";

//get log list
const getLogs = () => {
    const config = getToken();

    return axios
        .get(`${process.env.REACT_APP_API_URL}/logs`, config)
        .then((response) => {
            return response.data;
        });
};

// filter
const updateFilter = (login, first_name, last_name, action) => {
    const config = getToken();

    return axios
        .get(
            `${process.env.REACT_APP_API_URL}/search-logs?login=${login}&first_name=${first_name}&last_name=${last_name}&action=${action}`,
            config
        )
        .then((response) => {
            return response.data;
        });
};

//Get history by Id
const getLogById = (id) => {
    const config = getToken();

    return axios
        .get(`${process.env.REACT_APP_API_URL}/log/${id}`, config)
        .then((response) => {
            return response.data;
        });
};

export const logService = {
    getLogs,
    updateFilter,
    getLogById,
};

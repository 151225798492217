import React,{useState,useContext} from 'react';
import { useHistory  } from "react-router-dom";
import {authService} from '../../services/auth';
import useCheckMobileScreen from '../../utils/hooks/useCheckMobileScreen';
import { ThemeContext } from '../../utils/context/ThemeContext';
import './topbar.scss'


const Topbar = (props) => {

    const [open,setOpen]= useState(false);
    let history = useHistory();
    const { menuOpen, setMenuOpen } = useContext(ThemeContext);
    const isMobile = useCheckMobileScreen();

    const classMenu = !menuOpen ? 'opned' : 'clossed';

    const callbackOpen = () => {
        if(isMobile){
            setMenuOpen(true);
        }
        setOpen(!open);
    }

    const logoutCallback = async (e) => {
        e.preventDefault();
        await authService.logout().then(
            user => {
                console.log(user);
            }
        );
        localStorage.removeItem('user-external');
        history.push('/');
    }

    return(
        <>
        <div id="top-bar" className={classMenu}>
            <button className="menu-btn" onClick={() => setMenuOpen(!menuOpen)}>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-list"><line x1="8" y1="6" x2="21" y2="6"></line><line x1="8" y1="12" x2="21" y2="12"></line><line x1="8" y1="18" x2="21" y2="18"></line><line x1="3" y1="6" x2="3.01" y2="6"></line><line x1="3" y1="12" x2="3.01" y2="12"></line><line x1="3" y1="18" x2="3.01" y2="18"></line></svg>
            </button>
            <div className="text-right">
                <a href="#" onClick={callbackOpen} className="profile-btn" title="Signed in as Admin Person">
                    <img src="//www.gravatar.com/avatar/2d6826023f031865b164c14f78254c0e?s=90&amp;d=mm" className="account-avatar" loading="lazy" width="90" height="90" />
                </a>
            </div>
        </div>
        {open && isMobile && <div className='maskOpen' onClick={() => setOpen(!open)}></div>}
        <div className={open ? "user-menu open" : "user-menu"}>
            <ul>
                <li role="presentation">
                    <a className="user-sidebare-link">My account</a>
                </li>
                <li role="presentation">
                    <a onClick={logoutCallback} className="user-sidebare-link">
                        Sign out                                                    
                    </a>
                </li>
            </ul>
        </div>
    </>
    )

} 

export default Topbar
import './App.scss';
import Login from './components/Login';
import Dashboard from './components/Dashboard';
import {
  BrowserRouter,
  Switch,
  Redirect
} from "react-router-dom";
import PrivateRouter from './utils/PrivateRouter';
import PublicRouter from './utils/PublicRouter';
import AddOrder from './components/AddOrder';
import Cashier from './components/Cashier';
import AddCashier from './components/Cashier/AddCashier';
import UpdateCashier from './components/Cashier/UpdateCashier';
import Orders from './components/Orders';
import History from './components/History';
import DetailsOrder from './components/Orders/Details';
import DetailsLog from './components/History/Details';
import DetailsCashier from './components/Cashier/Details';
import PageNotFound from './components/PageNotFound';

function App() {
  return (
    <BrowserRouter>
        <Switch>
          <PublicRouter restricted={true} path="/" component={Login} exact />
          <PrivateRouter restricted={false} path="/dashboard" component={Dashboard} />
          <PrivateRouter restricted={false} path="/add-order" component={AddOrder} />
          <PrivateRouter restricted={false} path="/cashiers" component={Cashier} />
          <PrivateRouter restricted={false} path="/add-cashier" component={AddCashier} />
          <PrivateRouter restricted={false} path="/orders" component={Orders} />
          <PrivateRouter restricted={false} path="/order/:id" component={DetailsOrder} />
          <PrivateRouter restricted={false} path="/update-cashier/:id" component={UpdateCashier} />
          <PrivateRouter restricted={false} path="/history" component={History} />
          <PrivateRouter restricted={false} path="/logs/:id" component={DetailsLog} />
          <PrivateRouter restricted={false} path="/cashier/:id" component={DetailsCashier} />
          <PrivateRouter restricted={false} path="/404" component={PageNotFound} />
          <Redirect from="*" to="/404" />
        </Switch>
    </BrowserRouter>
  );
}

export default App;

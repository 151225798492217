import axios from "axios";
import { getToken } from "../utils/helper";

//Get Statistic
const getStatistic = (date_start, date_end) => {
    const config = getToken();

    return axios
        .get(
            `${process.env.REACT_APP_API_URL}/dashboard/statistic/${date_start}/${date_end}`,
            config
        )
        .then((response) => {
            return response.data;
        });
};

export const dashboardService = {
    getStatistic,
};

import React,{useEffect,useState} from 'react';
import DataTable, { createTheme } from 'react-data-table-component';
import { useHistory } from "react-router-dom";
import Layout from '../Layout';
import FilterComponent from './Filter';
import {logService} from '../../services/log';
import './history.scss'

const History = (props) => {

    const history = useHistory();
    const [loading,setLoading] = useState(true);
    const [data,setData] = useState([]);
    

    //Get Data First Time
    useEffect( async () => {
      await logService.getLogs().then(
        data => {
            setLoading(false);
            setData(data.data);
        }
      )
    },[])


    //Add Column
    const columns = [
        {
          name: 'Id',
          selector: 'id',
          sortable: true,
        },
        {
          name: 'Action',
          selector: 'title',
          sortable: true,
        },
        {
          name: 'Login',
          selector: 'vender.user.login',
          sortable: true,
        },
        {
          name: 'First Name',
          selector: 'vender.user.first_name',
          sortable: true,
        },
        {
          name: 'Last Name',
          selector: 'vender.user.last_name',
          sortable: true,
        },
        {
          name: 'Created At',
          selector: 'created_at',
          sortable: false,
        },
        {
          name: 'Actions',
          cell:(row) => <div className='table-btn'><button className="btn btn-default" onClick={() => history.push(`/logs/${row.id}`)} id={row.id}>View</button></div>,
          ignoreRowClick: true,
          allowOverflow: true,
          button: true,
          width: '150px',
        },
    ];


    //Create Datatable Theme
    createTheme('solarized', {
        text: {
          primary: '#000',
          secondary: '#2aa198',
        },
        background: {
          default: '#fff',
        },
        context: {
          background: '#cb4b16',
          text: '#FFFFFF',
        },
        divider: {
          default: '#ccc',
        },
        action: {
          button: 'rgba(0,0,0,.54)',
          hover: 'rgba(0,0,0,.08)',
          disabled: 'rgba(0,0,0,.12)',
        },
      });

    return(
          <Layout>
              <div className='control-toolbar'>
                <h1 className='heading_title'>History</h1>
                <FilterComponent setData={setData} setLoading={setLoading}/>
              </div>
              <DataTable
                  title="History"
                  columns={columns}
                  pagination
                  theme="solarized"
                  selectableRows 
                  data={data}
                  progressPending={loading}
                  striped
                  persistTableHead
                  responsive
              />
          </Layout>   
    )

} 

export default History
import React from 'react';
import { Route, Redirect } from 'react-router-dom';

const PublicRouter = ({ component: Component, roles, ...rest }) => {
    return (
        <Route {...rest} render={props => {
            if (localStorage.getItem('user-external')) {
                // not logged in so redirect to login page with the return url
                return <Redirect to={{ pathname: '/dashboard', state: { from: props.location } }} />
            }

            // logged in so return component
            return <Component {...props} />
        }} />
    );
};

export default PublicRouter;
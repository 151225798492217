  
import React, { createContext, useState } from 'react';
import useCheckMobileScreen from '../hooks/useCheckMobileScreen';

export const ThemeContext = createContext();

const ThemeContextProvider = (props) => {
	const isMobile = useCheckMobileScreen();
	const [menuOpen, setMenuOpen] = useState(!isMobile ? false : true);

	const { children } = props;
	return (
		<ThemeContext.Provider value={{ menuOpen, setMenuOpen }}>
 			{children}
		</ThemeContext.Provider>
	)
}

export default ThemeContextProvider;